import React from 'react'
import styled, { css } from 'styled-components'
import { string, node } from 'prop-types'
import { rgba, stripUnit } from 'polished'

import { widowFix } from 'utils/string'
import { media } from 'styles/media'
import { white, greyDark, primaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import SmallCaps from 'components/SmallCaps'

const Container = styled.div`
  position: static;
`

const Eyebrow = styled(SmallCaps)`
  display: block;
  color: ${primaryColor};
  margin-bottom: ${spacing.small};

  ${media.medium`
    margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};
`

const Title = styled(Heading)`
  margin-bottom: 0.35em;
  color: ${(props) => (props.isDark ? white : greyDark)};

  ${media.medium`
    margin-bottom: 0.4em;
  `};
`

const Subheading = styled.p`
  position: static;

  ${(props) =>
    props.isDark &&
    css`
      color: ${rgba(white, 0.7)};
    `}

  ${media.large`
    margin-bottom: 2em;
  `};
`

const SectionHeading = ({ eyebrow, heading, subheading, children, isDark }) => (
  <Container>
    {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
    <Title size={2} isDark={isDark}>
      {widowFix(heading)}
    </Title>
    {subheading && <Subheading isDark={isDark}>{subheading}</Subheading>}
    {children && <div>{children}</div>}
  </Container>
)

SectionHeading.propTypes = {
  eyebrow: string,
  heading: string.isRequired,
  subheading: string,
  children: node,
}

export default SectionHeading
