import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'
import Img from 'gatsby-image/withIEPolyfill'
import { useIntl } from 'gatsby-plugin-intl'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { black, white } from 'styles/colors'
import { cover } from 'styles/helpers'
import { media } from 'styles/media'
import { Lead } from 'styles/typography'
import { widowFix } from 'utils/string'
import BookButton from 'components/button/BookButton'
import SectionHeading from '../SectionHeading'

const Container = styled.div`
  position: relative;

  ${media.medium`
    background-attachment: fixed;
  `};
`

const Inner = styled.div`
  position: static;
  padding-top: 4rem;
  padding-bottom: 6rem;

  ${media.small`
    padding-top: 6rem;
    padding-bottom: 9rem;
  `};

  ${media.medium`
    padding-top: 8rem;
    padding-bottom: 13rem;
  `};

  ${media.large`
    padding-top: 10rem;
    padding-bottom: 15rem;
  `};

  ${media.xLarge`
    padding-top: 12rem;
    padding-bottom: 18rem;
  `};
`

const Content = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  z-index: 10;
`

const BackgroundWrapper = styled.div`
  ${cover('absolute')};

  overflow: hidden;

  > * {
    max-height: 100%;
    height: 100%;
  }
`

const CTAContainer = styled.div`
  margin-top: 1.5em;
`

const Hero = ({ cmsData, locationImageFluid }) => {
  const intl = useIntl()

  return (
    <Container>
      <Inner>
        <Wrapper size='narrow'>
          <Content>
            <SectionHeading isDark heading={widowFix(cmsData.heading[0].text)} subheading={cmsData.content[0].text} />
            {/* <CTAContainer>
              <ButtonGroup>
                <Button
                  theme='primary'
                  onClick={() =>
                    window.open(
                      `https://storage.googleapis.com/obarhotel/menu.pdf`
                    )
                  }
                >
                  {intl.formatMessage({ id: 'see-menu' })}
                </Button>
              </ButtonGroup>
            </CTAContainer> */}
          </Content>
        </Wrapper>
      </Inner>

      <BackgroundWrapper>
        <Img fluid={locationImageFluid} role='presentation' objectFit='cover' loading='eager' fadeIn={false} />
      </BackgroundWrapper>
    </Container>
  )
}

export default Hero
