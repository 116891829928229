import React from 'react'
import styled from 'styled-components'
import { stripUnit } from 'polished'
import { useIntl } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { boxShadow } from 'styles/helpers'
import { fontWeights } from 'styles/typography'
import { greyDark, white } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Map from 'components/Map'
import Heading from 'components/Heading'
import SocialLinks from 'components/SocialLinks'

const Container = styled.div`
  position: static;

  ${media.medium`
    display: flex;
    align-items: center;
  `};
`

const MapWrap = styled.div`
  position: relative;

  ${media.medium`
    width: 80%;
  `};

  ${media.large`
    width: 85%;
  `};

  &:after {
    content: '';
    display: block;
    padding-bottom: 85%;

    ${media.small`
      padding-bottom: 80%;
    `};

    ${media.medium`
      padding-bottom: 75%;
    `};

    ${media.large`
      padding-bottom: 66%;
    `};

    ${media.xLarge`
      padding-bottom: 60%;
    `};
  }
`

const Details = styled.aside`
  position: relative;
  background-color: ${white};
  padding: ${spacing.large};
  z-index: 10;
  margin-left: ${spacing.medium};
  margin-right: ${spacing.medium};
  margin-top: -${spacing.xxxLarge};
  ${boxShadow};

  ${media.small`
    margin-left: ${spacing.large};
    margin-right: ${spacing.large};
    padding: ${spacing.xLarge};
  `};

  ${media.medium`
    width: 300px;
    margin-left: -120px;
    margin-top: 0;
    margin-right: 0;
  `};

  ${media.large`
    padding: ${spacing.xxLarge};
  `};

  ${media.xLarge`
    width: auto;
    flex: 1;
    margin-left: -160px;
    padding: 48px;
  `};
`

const Social = styled.div`
  color: ${greyDark};
`

const Section = styled.section`
  position: static;

  & + & {
    margin-top: ${spacing.large};

    ${media.medium`
      margin-top: ${spacing.xLarge};
    `};

    ${media.xLarge`
      margin-top: ${spacing.xxLarge};
    `};
  }
`

const Address = styled.a`
  display: block;
`

const StyledHeading = styled(Heading)`
  font-weight: ${fontWeights.bold};
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Directions = () => {
  const intl = useIntl()

  return (
    <Container>
      <MapWrap>
        <Map
          defaultCenter
          center={{ lat: 45.491498, lng: -73.558447 }}
          zoom={16}
        />
      </MapWrap>
      <Details>
        <Section>
          <StyledHeading size={6} variant='sans'>
            {intl.formatMessage}
          </StyledHeading>
          <Address
            href='https://www.google.com/maps/place/GH+Griffintown+Hotel/@45.49148,-73.5585421,15z/data=!4m16!1m7!3m6!1s0x4cc91a8a76b3a3e1:0xc1b204af6477ce81!2s1306+Basin+St,+Montreal,+QC+H3C+0P9!3b1!8m2!3d45.4914637!4d-73.5584282!3m7!1s0x4cc91becccfbcdcd:0xae0241da35d145f8!5m2!4m1!1i2!8m2!3d45.4914427!4d-73.5584031'
            target='_blank'
            rel='noopener'
          >
            1222 rue des Bassins
            <br />
            Montreal, Quebec
            <br />
            Canada H3C 0X7
          </Address>
        </Section>
        <Section>
          <StyledHeading size={6} variant='sans'>
            {intl.formatMessage({ id: 'contact.getInTouch' })}
          </StyledHeading>
          <a href='tel:18555770222'>1-855-577-0222</a>
          <br />
          <a href='mailto:reservation@griffintownhotel.com'>
            reservation@griffintownhotel.com
          </a>
        </Section>
        <Section>
          <Social>
            <SocialLinks
              items={[
                {
                  link: 'https://www.facebook.com/griffintownhotel/',
                  icon: 'facebook',
                },
                { link: 'https://twitter.com/griffintownh', icon: 'twitter' },
                {
                  link: 'https://www.instagram.com/griffintownhotel/',
                  icon: 'instagram',
                },
              ]}
            />
          </Social>
        </Section>
      </Details>
    </Container>
  )
}

export default Directions
