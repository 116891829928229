import Footer from 'components/Footer'
import { node } from 'prop-types'
import React from 'react'
import { GlobalStyle } from 'styles/global'

const BarLayout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
    <Footer />
  </>
)

BarLayout.propTypes = {
  children: node.isRequired,
}

export default BarLayout
