import Hero from 'components/bar/Hero'
import Location from 'components/bar/Location'
import Directions from 'components/Directions'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import BarLayout from 'components/bar/Layout'
import React from 'react'
import { graphql } from 'gatsby'
// import { useIntl } from 'gatsby-plugin-intl'

const Bar = ({ data }) => {
  const heroData = data.prismic.allBas.edges[0].node
  const locationData = data.prismic.allBar_page_locations.edges[0].node

  const {
    logoImage: {
      childImageSharp: { fluid: logoImageFluid },
    },
    heroImage: {
      childImageSharp: { fluid: heroImageFluid },
    },
    locationImage: {
      childImageSharp: { fluid: locationImageFluid },
    },
  } = data

  return (
    <BarLayout>
      <MetaTags title='Hotel Griffintown' />
      <Hero
        logoImageFluid={logoImageFluid}
        heroImageFluid={heroImageFluid}
        cmsData={heroData}
      />
      <Location
        cmsData={locationData}
        locationImageFluid={locationImageFluid}
      />
      <Section>
        <SectionBlock>
          <Wrapper>
            <Directions />
          </Wrapper>
        </SectionBlock>
      </Section>
    </BarLayout>
  )
}

export const query = graphql`
  query BarPage($language: String!) {
    logoImage: file(name: { eq: "logo-obar-white" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImage: file(name: { eq: "background-wood-black-with-cocktail" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locationImage: file(name: { eq: "background-wood-black" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismic {
      allBas(lang: $language) {
        edges {
          node {
            heading
            subheading
            content
          }
        }
      }
    }
    prismic {
      allBar_page_locations(lang: $language) {
        edges {
          node {
            heading
            content
          }
        }
      }
    }
  }
`

export default Bar
