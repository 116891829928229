import Button from 'components/button/Button'
import BookButton from 'components/button/BookButton'
import ButtonGroup from 'components/button/ButtonGroup'
import Nav from 'components/Nav'
import Wrapper from 'components/Wrapper'
import Img from 'gatsby-image/withIEPolyfill'
import { useIntl } from 'gatsby-plugin-intl'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { white } from 'styles/colors'
import { cover } from 'styles/helpers'
import { media } from 'styles/media'
import { Lead } from 'styles/typography'
import { navHeight, navHeightMobile } from 'styles/variables'

const Container = styled.div`
  position: relative;
  padding-top: ${navHeightMobile};

  ${media.medium`
    padding-top: ${navHeight};
    background-attachment: fixed;
  `};
`

const BackgroundWrapper = styled.div`
  ${cover('absolute')};

  overflow: hidden;

  > * {
    max-height: 100%;
    height: 100%;
  }
`

const Inner = styled.div`
  position: static;
  padding-top: 4rem;
  padding-bottom: 6rem;

  ${media.small`
    padding-top: 6rem;
    padding-bottom: 9rem;
  `};

  ${media.medium`
    padding-top: 8rem;
    padding-bottom: 13rem;
  `};

  ${media.large`
    padding-top: 10rem;
    padding-bottom: 15rem;
  `};

  ${media.xLarge`
    padding-top: 12rem;
    padding-bottom: 18rem;
  `};
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  max-width: 430px;

  ${media.small`
    max-width: 460px;
  `};

  ${media.medium`
    max-width: 520px;
  `};

  ${media.large`
    max-width: 530px;
  `};

  ${media.xLarge`
    max-width: 560px;
  `};
`

const Subtitle = styled(Lead)`
  color: ${rgba(white, 0.7)};
`

const Hero = ({ cmsData, heroImageFluid, logoImageFluid }) => {
  const intl = useIntl()

  return (
    <Container>
      <Nav isBar />
      <Inner>
        <Wrapper size='narrow'>
          <Content>
            <Img
              fluid={logoImageFluid}
              role='presentation'
              objectFit='contain'
              loading='eager'
              fadeIn={false}
              style={{
                width: 150,
                marginBottom: 20,
              }}
            />
            <Subtitle>{cmsData.content[0].text}</Subtitle>
            {/* <ButtonGroup>
              <Button
                theme='primary'
                onClick={() =>
                  window.open(
                    `https://storage.googleapis.com/obarhotel/menu.pdf`
                  )
                }
              >
                {intl.formatMessage({ id: 'see-menu' })}
              </Button>
            </ButtonGroup> */}
          </Content>
        </Wrapper>
      </Inner>

      <BackgroundWrapper>
        <Img fluid={heroImageFluid} role='presentation' objectFit='cover' loading='eager' fadeIn={false} />
      </BackgroundWrapper>
    </Container>
  )
}

export default Hero
